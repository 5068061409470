import React from "react";
import Img from "gatsby-image"
import { graphql, useStaticQuery } from 'gatsby';

// export default Image = () => {
//   const data = useStaticQuery(
//     graphql`
//     {
//       testFluid: file(relativePath: { eq: "about.jpg" }) {
//           childImageSharp {
//           fluid(maxWidth: 800) {
//               ...GatsbyImageSharpFluid
//           }
//         }
//       }
//     }
//   `
//   )
//     // return (
//     // <Img fluid={data.testFluid.childImageSharp.fluid} alt="test fluid" />
//     // )
    
// }

export default props => {
  const { allImageSharp } = useStaticQuery(graphql`
    query {
      allImageSharp {
        nodes {
          fluid(maxWidth: 1600) {
            originalName
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <figure className={props.className} style={props.style}>
      <Img
        fluid={
          allImageSharp.nodes.find(n => n.fluid.originalName === props.filename)
            .fluid
        }
        alt={props.alt}
      />
    </figure>
  )
}