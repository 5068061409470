import React from "react"
import '../css/style.css';

class AssetBalance extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      // balance 
      data: [],
      asset: null,
      quantity: null,
      unconfirmed_quantity: null,
    }
  }
  async componentDidMount() {
    let q1, q2, uq1, uq2;

    // balance01
    let req1 = Promise.resolve(
      await fetch('https://mpchain.info/api/balance/MC4PVjnEF2ZXSdeGGA3KfhDtNq9F9FGXEJ/XMP', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(response => response.json())
      .then(data => {
        q1 = data.quantity;
        uq1 = data.unconfirmed_quantity;
      })
      .catch((error) => {
      })
    ); 
  
    // balance02
    let req2 = Promise.resolve(
      await fetch('https://mpchain.info/api/balance/MHpZiWZUaoGrj8MkTRwDBXLHCCDN3Beddc/XMP', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(response => response.json())
      .then(data => {
        q2 = data.quantity;
        uq2 = data.unconfirmed_quantity;
      })
      .catch((error) => {
      })
    );
    Promise.all([req1, req2]).then((r) => {
      this.setState({
        quantity: parseInt(q1) + parseInt(q2),
        unconfirmed_quantity: parseInt(uq1) + parseInt(uq2),
      })
    });
  }
  
  render() {
    return (
      <div className="lg:mt-0 mb-2 lg:mb-0 text-2xl sm:text-5xl font-bold text-center">
        <div className="leading-tight">
          <p>枯渇するまであと</p>
          <p className="text-3xl sm:text-6xl">{this.state.quantity}XMP&#x1f923;</p>
          <p>({this.state.unconfirmed_quantity})</p>
        </div>
      </div>
    )
  }
}

export default AssetBalance;