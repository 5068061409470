import React from "react";
import Layout from '../components/layout/Layout';
import AddressBalance from '../components/AssetBalance';
import { Link } from 'gatsby';
import Image from "../components/Images";
import { MonapaImage } from "../components/MonapaImage";
import "../style/styles.css"
const link1 = "https://mpchain.info/address/MC4PVjnEF2ZXSdeGGA3KfhDtNq9F9FGXEJ"
const link2 = "https://mpchain.info/address/MHpZiWZUaoGrj8MkTRwDBXLHCCDN3Beddc"

const IndexPage = () => (
  <Layout>
    <div className="lg:px-10">
      <div className="w-full h-full px-4 md:px-32 mt-12 mx-auto flex justify-center items-center">
        <div className='w-full '>
          <AddressBalance />
          <div className="py-4 text-xs md:text-xl font-bold text-center">
            <p>みなさまXMPの寄付をありがとうございます。XMPは下記2つのアドレスにて別々の場所にて管理しています。</p>
            <a href={ link1 }>（配布用）MC4PVjnEF2ZXSdeGGA3KfhDtNq9F9FGXEJ &#x1f440;</a><br/>
            <a href={ link2 }>（補充用）MHpZiWZUaoGrj8MkTRwDBXLHCCDN3Beddc &#x1f440;</a>
            <div className="text-xs sm:text-lg mt-2">
              <p className="animate-spin inline-flex px-2">&#x1f603;</p>
              <p className="inline-flex">節度を持って利用しましょう</p>
              <p className="animate-spin inline-flex px-2">&#x1f603;</p>
            </div>
          </div>
          <div className="lg:mt-0 mb-2 lg:mb-0 text-2xl md:text-4xl font-bold text-center">
            <div className="sm:hidden w-2/3 mx-auto mb-6 justify-center items-center">
              <MonapaImage />
            </div>
            <Link to="/about" className="mb-6 py-4 md:pb-4 rounded-lg border-2 border-solid w-2/3 sm:w-1/2 mx-auto flex justify-center items-center hover:opacity-75" style={{borderColor: 'rgb(222, 242, 217)'}}>About</Link>
            <Link to="/faucet" className="mb-6 py-4 md:pb-4 rounded-lg border-2 border-solid w-2/3 sm:w-1/2 mx-auto flex justify-center items-center hover:opacity-75" style={{borderColor: 'rgb(222, 242, 217)'}}>&#x1f334; KOJIKI &#x1f98d;</Link>
            <Link to="/history" className="mb-6 py-4 md:pb-4 rounded-lg border-2 border-solid w-2/3 sm:w-1/2 mx-auto flex justify-center items-center hover:opacity-75" style={{borderColor: 'rgb(222, 242, 217)'}}>History</Link>
            <Link to="/xmp" className="mb-6 py-4 md:pb-4 rounded-lg border-2 border-solid w-2/3 sm:w-1/2 mx-auto flex justify-center items-center hover:opacity-75" style={{borderColor: 'rgb(222, 242, 217)'}}>XMP?</Link>
            <Link to="/mpurse" className="mb-6 py-4 md:pb-4 rounded-lg border-2 border-solid w-2/3 sm:w-1/2 mx-auto flex justify-center items-center hover:opacity-75" style={{borderColor: 'rgb(222, 242, 217)'}}>Mpurse?</Link>
            <a href="https://monapalogo.kotaroo.work/"><Image className="animate-wiggle w-full sm:w-1/2 mx-auto mt-8 justify-center items-center" filename="monapalogo_ogp.jpg" alt="MonapartyFreeLogo" /></a>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default IndexPage;